import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { ButtonPrimary } from "../../../components/widgets/button";
import { ContentPlusDecor } from "../../../components/widgets/content-plus-decor";
import { FaPenFancy } from "react-icons/fa";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Quant Developers & Writers`}</h1>
    <Breadcrumbs to="/company/" text="Company" mdxType="Breadcrumbs" />
    <ContentPlusDecor decor={<FaPenFancy mdxType="FaPenFancy" />} mdxType="ContentPlusDecor">
      <p><em parentName="p">{`TuringTrader.com`}</em>{` is growing rapidly around an awesome nucleus of ambitious DIY investors, just like you. If you have an idea for a quant strategy, we want to hear about it.`}</p>
      <h2>{`What We Are Looking For`}</h2>
      <p>{`At `}<em parentName="p">{`TuringTrader.com`}</em>{`, we aim to cover a wide array of trading styles and asset classes. We then combine multiple of these strategies into `}<a parentName="p" {...{
          "href": "/articles/all-stars-portfolios/"
        }}>{`All-Stars Portfolios`}</a>{` to reap additional diversification benefits.`}</p>
      <p>{`If your strategy trades an asset class or uses an investment approach we are currently not covering, you've got our attention. We'll be honest, though: developing for `}<em parentName="p">{`TuringTrader.com`}</em>{` takes work. We want your strategy to be at its best, and we'll push you to get there. Once accepted, you'll get extensive feedback from us, and we'll work closely with you to polish the results.`}</p>
      <p>{`At the same time, developing and writing for us is also rewarding. Thousands of your peers, including potential employers, will see your work, and you will also learn a lot in the process. About communicating your ideas, writing, presenting your results, and the technical infrastructure to run a site like `}<em parentName="p">{`TuringTrader.com`}</em>{`.`}</p>
      <h2>{`How to Submit`}</h2>
      <p>{`First, you should contact us and let us know what you are working on. In that initial communication, we'd like to hear about the following:`}</p>
      <ul>
        <li parentName="ul">{`Trading style and assets traded. We need to see how your idea fits our current portfolios and how much of a new angle your strategy adds.`}</li>
        <li parentName="ul">{`High-level insight into the algorithm. We need to understand enough about your strategy toassess the complexity of implementation and the overlap with algorithms we already have. We explicitly do `}<em parentName="li">{`not`}</em>{` want to hear about your secret sauce at this stage.`}</li>
        <li parentName="ul">{`Backtesting results. We want to see how well your strategy has performed, review its key metrics, and its correlation with our current strategies.`}</li>
        <li parentName="ul">{`Backtesting environment and requirements. We need to evalute the effort to port your strategy to the TuringTrader engine and consider any additional technical or data requirements.`}</li>
      </ul>
      <h2>{`What Happens Next`}</h2>
      <p>{`Here is what happens after you hit send:`}</p>
      <ul>
        <li parentName="ul">{`We will review your submission and determine whether it's a potential fit.`}</li>
        <li parentName="ul">{`We'll get back to you with notes, questions, and feedback.`}</li>
        <li parentName="ul">{`If we accept your submission, we will work closely with you on porting your strategy to our environment, integrating it with our site, and pairing it with other strategies for an All-Stars portfolio. We will then schedule your strategy for publication on our monthly rollout schedule.`}</li>
      </ul>
      <p>{`What are you waiting for?`}</p>
      <ButtonPrimary text="Contact Us" to="/company/contact" mdxType="ButtonPrimary" />
      <br />
      <br />
      <br />
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      